<div class="tw-text-center tw-py-2">

    <div class="tw-flex tw-flex-col tw-items-center">

        <div class="tw-flex tw-justify-start tw-w-1/4 tw-pb-2 md:tw-pb-4">
            <div class="tw-flex tw-flex-row tw-flex-wrap tw-items-center">
                <div class="tw-bg-white tw-rounded-full tw-w-12 tw-h-12 tw-flex tw-border tw-border-gray-900 tw-shadow-lg">
                    <a href="https://elixir-europe.org/" class="tw-block tw-self-center">
                        <img src="assets/logos/ELIXIR_logo.svg" alt="ELIXIR logo"
                            class="tw-p-1 tw-rounded-full tw-w-full ">
                    </a>
                </div>
                <div class="tw-relative tw-flex-grow tw-max-w-md tw-flex-1 tw-px-4 tw-mx-0">
                    <p class="tw-mt-0 tw-mb-0 tw-font-bold tw-text-gray-800 tw-text-sm tw-text-justify">APICURON is part
                        of the <a href="https://elixir-europe.org/">ELIXIR infrastructure</a>
                </div>
            </div>
        </div>

        <div class="tw-flex tw-justify-start tw-w-1/4 tw-pb-2 md:tw-pb-4">
            <div class="tw-flex tw-flex-row tw-flex-wrap tw-items-center">
                <div
                    class="tw-bg-white tw-rounded-full tw-w-12 tw-h-12 tw-flex tw-border tw-border-gray-900 tw-shadow-lg">
                    <a href="https://www.biocuration.org/" class="tw-block tw-self-center">
                        <img src="assets/logos/isb.jpg" alt="ISB logo" class="tw-p-[1px] tw-rounded-full tw-w-full">
                    </a>
                </div>
                <div class="tw-relative tw-flex-grow tw-max-w-md tw-flex-1 tw-px-4 tw-mx-0">
                    <p class="tw-mt-0 tw-mb-0 tw-font-bold tw-text-gray-800 tw-text-sm tw-text-justify">APICURON is
                        endorsed by the
                        International Society for Biocuration (ISB) as a service dedicated to crediting and recognizing
                        the invaluable work of professional and volunteer biocurators.
                </div>
            </div>
        </div>
    </div>

    <span class="tw-block tw-font-bold tw-text-gray-800 tw-text-sm">
        APICURON: a database to credit and acknowledge the work of biocurators
    </span>
    <span class="tw-block tw-font-bold tw-text-gray-800 tw-text-sm">
        András Hatos, Federica Quaglia, Damiano Piovesan, Silvio Tosatto
        Database (2021);
    </span>
    <div>
        <a class="tw-mr-2 credit-link" href="https://doi.org/10.1093/database/baab019">DOI: 10.1093/database/baab019<img
                class="tw-inline" src="assets/link.svg" alt="" srcset=""></a>
        <a class="credit-link" href="https://pubmed.ncbi.nlm.nih.gov/33882120/">pubmed:33882120<img class="tw-inline"
                src="assets/link.svg" alt="" srcset=""></a>
    </div>
</div>